import React, { useEffect }  from 'react'
import './TermsCondition.css';
import { Link } from 'react-router-dom';

function TermsCondition() {
    useEffect(() => {
        window.scrollTo(0, 0); 
      }, []);
    const navigationsubforlder = [
        {   
             id:1,
            name:<div className="font_main_p text-dark-emphasis">Purpose</div>,
            para:<div className='font-14'>360 Degree Cloud and its affiliate provide the content on this Website subject to the following terms and conditions. We can periodically change these terms, so make sure You are always aware of the updated terms. By accessing the Website, You agree to these Terms of Use.</div>
        },
        {   id:2,
            name:<div className="font_main_p text-dark-emphasis">Definitions</div> ,
            para:<div className='font-14'>2.1. “We”, “Our”, “Company”, “Us”, “360 Degree Cloud” refers to “360 Degree Cloud LLC, 1968 S.Coast Hwy #1412 Laguna Beach California 92651” and its Affiliates.<br/>2.2. “User”, ‘You’ refers to a person using a computer, network service, browser, Website, or app.<br/>
            (User’s and Your are used as possessives.)<br/>2.3. A “Lead” refers to anyone that shows interest in Our services via an Opt-In such as the Reserved Keyword ‘DEMO’ or the webform titled ‘Contact Us’ on Our Website.<br/>2.4. “ Services” refers to the products and services advertised to Users visiting Our Website or provided by 360 Degree Cloud or its Affiliates to any customers, Users, or Leads.<br/>2.5. “Third Party” (or plurally “Third Parties”) refers to any entities working outside of Our Company, the Client, and either of their Affiliates that provide or vend specialized software services or dependencies.<br/>2.6. “Website” or “Site” means the 360 Degree Cloud Website accessible at https://360degreecloud.com/ as the case may be.<br/>2.7. “Contact Us” is the Opt In form for Our Lead Generation Program.<br/>2.8. “Lead Generation Program” refers to the detailed lead generation through demonstration of Our Services.<br/>2.9. “Leads” refer to the people who show interest in Our services via an Opt-In such as the reserved keyword ‘DEMO’ or the webform titled Contact Us page on Our Website.<br/>2.10. “Reserved Keywords” refer to text messaging keywords configured for Our Lead generation Program.<br/>2.11. “Messaging Charges & Frequency” refers to the rates that may apply to Messaging and data. It also refers to the frequency of the messages sent to the recipients varies.
            </div>
            
        },
        {   id:3,
            name:<div className="font_main_p text-dark-emphasis">Copyrights</div>,
            para:<div className='font-14'>All content and functionality on the Website, including text, graphics, logos, and images and their selection and arrangement, is the exclusive property of 360 Degree Cloud and is protected by international copyright laws. All rights not expressly granted are reserved.
                </div>
        },
        {   id:4,
            name:<div className="font_main_p text-dark-emphasis">Trademarks</div>,
            para:<div className='font-14'>
                <p>The trademarks, designs, and logos that are given on the Website are trademarks of 360 Degree Cloud. You agree that You will not attribute any information or refer to 360 Degree Cloud in a public resOurce for promotion or advertising purposes. You also agree that You will not inform or influence any Third Party to reproduce any trademark of or imply any endorsement in relationship with 360 Degree Cloud.</p>
            </div>
        },
        {   id:5,
            name:<div className="font_main_p text-dark-emphasis">Marketing Consent</div>,
            para:<div className='font-14'>
                <p>By opting in to become a Lead, You and Users, provide Us and Our agents express permission and authorization to send text messages to the number or numbers You provide Us or Our agents during the intake and onboarding process, and thereafter.</p>
                <p>If You opt-in to become a Lead, You guarantee and represent that You are the subscriber of the number You provide Us and that You have the authority to give such consent.</p>
                <p>You may revoke this consent at any time while speaking to Us, Our Affiliates, or Our agents by sending an email with “Unsubscribe” as the subject line to <Link to="sales@360degreecloud.com" className='link-info link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover'>sales@360degreecloud.com.</Link></p>
                <p>5.1. Reserved Keywords<br/>Messaging keywords received by Us from You are processed as follows:</p>
                <p>5.1.1. Receiving ‘DEMO’ Opts You in as a Lead for demonstration of Our Services.</p>
                <p>5.1.2. Reply STOP to cancel further messages from going out to You..</p>
                <p>5.1.3. Reply HELP for help with Your inquiry.</p>
                <p>5.2. Messaging Charges<br/>Applicable messaging and data rates may apply to the messages You send to and receive from Us after opting in to become a Lead.</p>
                <p>5.3. Liability<br/>Our Carriers are not liable for delayed or undelivered messages,and any inconvenience caused thereof.</p>
            </div>
        },
        {   id:6,
            name:<div className="font_main_p text-dark-emphasis">Prohibited Use of the Website</div>,
            para:<div className='font-14'>
                <p>By accessing the Website, You are agreeing that You will not use the site in violation of these terms;</p>
                <p>6.1. Copy or create a derivative work, or try to discover any source code, or assign any Third Party for doing so;</p>
                <p>6.2. Sell, sublicense, assign, or commercially exploit any content or make it available to any Third Party;</p>
                <p>6.3. Use the Website in any way that can disable, damage, or impair the Website or its interfaces;</p>
                <p>6.4. Try to gain unauthorized access to the Website;</p>
                <p>6.5. Frame or mirror the Site or any part of it on any other Website;</p>
                <p>6.6. Use any software or device to interfere with the working of the Website.</p>
            </div>
        },
        {   id:7,
            name:<div className="font_main_p text-dark-emphasis">Privacy Policy</div>,
            para:<div className='font-14'>
                <p>By using this Website, you are accepting all the terms mentioned in Our Privacy Policy.<br/>
                Please check Our <Link to={"/privacy-policies"} className='link-info link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover'>Privacy Policy</Link> to learn more</p>
            </div>
        },
        {   id:8,
            name:<div className="font_main_p text-dark-emphasis">Changes</div>,
            para:<div className='font-14'>
                <p>We reserve the right to modify or replace these Terms at any time at Our sole discretion, without giving you prior notice. Your use of the Website following any such modification constitutes your acceptance to follow these Terms.</p>
            </div>
        },
        {   id:9,
            name:<div className="font_main_p text-dark-emphasis">Notices of Infringement</div>,
            para:<div className='font-14'>
                <p>360 Degree Cloud prohibits posting any content that violates or infringes the copyright rights or intellectual property rights of any person or entity. If you believe that any of your intellectual property rights are infringed by anything mentioned on the Website, please write to 360 Degree Cloud.</p>
            </div>
        },
        {   id:10,
            name:<div className="font_main_p text-dark-emphasis">Disclaimers</div>,
            para:<div className='font-14'>
                <p>360 Degree Cloud makes no representations about availability, reliability, timeliness, suitability, or accuracy of the content on the Website for any purpose. The content on the Website is delivered on an “As-Is” and “As-Available” basis.</p>
                <p>360 Degree Cloud shall have no responsibility or liability for any information published on linked Sites. We shall not be liable for any direct indirect, consequential, incidental damage, or the loss of revenues, whether or not advised of the possibility of such damage.</p>
                <p>This disclaimer is applied to any damage, liability, or injuries caused by any failure of performance, omission, error, defect, interruption, delay in operation, communication line failure, computer virus, theft, alterations, or destruction of information, whether for breach of contract, negligence, tory or any other cause of action.</p>
                </div>
        },
        {   id:11,
            name:<div className="font_main_p text-dark-emphasis">Contact Us</div>,
            para:<div className='font-14'>
                <p>If you have any questions or comments about the Terms of Use, please contact Us at:</p>
                <p className='Bot_bold'>
                360 Degree Cloud LLC<br/>
1968 S. Coast Hwy #1412 Laguna Beach California 92651<br/>
Tel:+1 360 663 4309
                </p>
            </div>
        },
      ]

    //   const currentDate = new Date();

  // Format the date as a string (e.g., "October 17, 2023")
//   const formattedDate = currentDate.toLocaleDateString();
  return (
    <div className='p-100'>
        <div className='text-center pt-3'>
            <p className='fw-semibold fs-5 text-body-emphasis'>Terms and Conditions</p>
        </div>
        <div className='px-2 px-lg-5'>
            <ol className="list-group list-group-numbered">
            {navigationsubforlder.map((item) => (
            <li className="list-group-item d-flex justify-content-between align-items-start p-2 border border-0">
                    <div className="ms-2 me-auto">
                        <div className="fw-bold">{item.name}</div>
                        {item.para}
                    </div>
                </li>
            ))}


            </ol>
        </div>
    </div>
  )
}

export default TermsCondition