import React from 'react';
import './landing.css';
import bannerimg from '../img/banner4.jpg';
import synccoreicon from '../img/Sync-Effortlessly.png';
import quickcoreicon from '../img/Quick-Document-Preview.png'; 
import smartcoreicon from '../img/Smart-Shortcuts.png'; 
import customizecoreicon from '../img/Customize-Your-Way.png'; 
import hasslefreecoreicon from '../img/Hassle-Free-Data-Sync.png'; 
import realtimecoreicon from '../img/Real-Time-Collaboration.png'; 
import filesyn1 from '../img/Real-Time-Collaborationbl.png'; 
import filesyn2 from '../img/Enhanced-User-Experiencebl.png';
import filesyn3 from '../img/004.png';
import filesyn4 from '../img/003.png';
import filesyn5 from '../img/002.png';
import filesyn6 from '../img/001.png';
import Carouselcmp from '../Carouselcmp';

function landing() {
  return (
    <div className='pt-100'>
      <div>
      <Carouselcmp/>
      </div>
      <section>
        <div className='p-4'>
          <p className='fs-1 text-center text-dark-emphasis fw-bold'>CORE FEATURES</p>
          <p className='text-center text-secondary fw-bold'>What's in Store for You?</p>
          <div className='row pad_cust_lr mt-5'>
            <div className='col-sm-12 col-lg-4 col-md-4'>
              <div class="shadow p-4 mb-5 bg-body-tertiary  rounded">
                <div class="card-body text-secondary card_body_cust text-center">
                  <img src={synccoreicon} alt='' className='img-fluid p-md-3 image_drop'/>
                  <p className='fw-semibold mb-2 fs_4'>Sync Effortlessly</p>
                  <p className='fs_14'>Real-time collaboration as Salesforce and Google Drive sync effortlessly.</p>
                </div>
              </div>
            </div>
            <div className='col-sm-12 col-lg-4 col-md-4'>
              <div class="p-4 mb-5 bg-body-tertiary  rounded bg-warning-subtle">
                <div class="card-body text-secondary card_body_cust text-center">
                <img src={quickcoreicon} alt='' className='img-fluid p-md-3 image_drop'/>
                  <p className='fw-semibold mb-2 fs_4'>Quick Document Preview</p>
                  <p className='fs_14'>Preview documents directly within Salesforce for swift access.</p>
                </div>
              </div>
            </div>
            <div className='col-sm-12 col-lg-4 col-md-4'>
              <div class="shadow p-4 mb-5 bg-body-tertiary  rounded">
                <div class="card-body text-secondary card_body_cust text-center">
                <img src={smartcoreicon} alt='' className='img-fluid p-md-3 image_drop'/>
                  <p className='fw-semibold mb-2 fs_4'>Smart Shortcuts</p>
                  <p className='fs_14'>Navigate easily with smart shortcuts for related object folders and files.</p>
                </div>
              </div>
            </div>
          </div>
          <div className='row pad_cust_lr'>
            <div className='col-sm-12 col-lg-4 col-md-4'>
              <div class="p-4 mb-5 bg-body-tertiary  rounded bg-warning-subtle">
                <div class="card-body text-secondary card_body_cust text-center">
                <img src={customizecoreicon} alt='' className='img-fluid p-md-3 image_drop'/>
                  <p className='fw-semibold mb-2 fs_4'>Customize Your Way</p>
                  <p className='fs_14'>Highly customizable to align with your unique business processes.</p>
                </div>
              </div>
            </div>
            <div className='col-sm-12 col-lg-4 col-md-4'>
              <div class="shadow p-4 mb-5 bg-body-tertiary  rounded">
                <div class="card-body text-secondary card_body_cust text-center">
                <img src={hasslefreecoreicon} alt='' className='img-fluid p-md-3 image_drop'/>
                  <p className='fw-semibold mb-2 fs_4'>Hassle-Free Data Sync</p>
                  <p className='fs_14'>Smooth transition with seamless syncing of existing data.</p>
                </div>
              </div>
            </div>
            <div className='col-sm-12 col-lg-4 col-md-4'>
              <div class="p-4 mb-5 bg-body-tertiary  rounded bg-warning-subtle">
                <div class="card-body text-secondary card_body_cust text-center">
                <img src={realtimecoreicon} alt='' className='img-fluid p-md-3 image_drop'/>
                  <p className='fw-semibold mb-2 fs_4'>Real-Time Updates</p>
                  <p className='fs_14'>Stay on top of any changes with instant syncing for live collaboration.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='background_img p-4 py-5'>
        <div className='container p_mob'>
          <div className='row justify-content-start align-items-center'>
              <div className='col-md-6 col-sm-12 col-lg-4'>
                <div className='border border-5 border-white'>
                  <img src={bannerimg} alt='' className='img-fluid p-md-3 image_drop'/>
                </div>
              </div>
              <div className='col-md-6 col-sm-12 col-lg-8 text-white'>
                
                <p className='fs-1 fw-bold'>INTEGRATING SALESFORCE AND GOOGLE DRIVE</p>
                <p>GET THE BEST OF BOTH WORLDS</p>
                <p className='text_core py-sm-3'>Experience the seamless integration of Salesforce and Google Drive with 360 File Sync, where the power of two robust platforms converges to redefine your document management experience.</p>
                <p className='text_core py-sm-3'>Now, you can effortlessly harness the collaborative strength of Salesforce and Google Drive in a single, unified solution.</p>
              </div>
          </div>
        </div>
      </section>
      <section className='body'>
        <div className='p-4'>
          <p className='text-center text-secondary fw-bold'>Experience Effortless Document Management with </p>
          <p className='fs-1 text-center text-dark-emphasis fw-bold'>360 FILE SYNC</p>
          <div className='row pad_cust_lr mt-5'>
            <div className='col-sm-12 col-lg-4 col-md-4'>
              <div class="shadow p-4 bg-body-tertiary rounded translate_cls">
                <div class="card-body text-secondary card_body_cust text-center">
                <div><img src={filesyn1} alt='' className='img-fluid p-md-3 image_drop'/>
                  <p className='fw-semibold mb-2 fs_4'>Real-Time Collaboration</p></div>
                  <p>Empower teams with live document syncing for updated information.</p>
                </div>
              </div>
            </div>
            <div className='col-sm-12 col-lg-4 col-md-4'>
              <div class="p-4 bg-body-tertiary bod_custom rounded translate_cls">
                <div class="card-body text-secondary card_body_cust text-center">
                <img src={filesyn2} alt='' className='img-fluid p-md-3 image_drop'/>
                  <p className='fw-semibold mb-2 fs_4'>Enhanced User Experience</p>
                  <p>Quick access to critical info with in-platform document preview.</p>
                </div>
              </div>
            </div>
            <div className='col-sm-12 col-lg-4 col-md-4'>
              <div class="shadow p-4 bg-body-tertiary translate_cls rounded translate_cls">
                <div class="card-body text-secondary card_body_cust text-center">
                <img src={filesyn3} alt='' className='img-fluid p-md-3 image_drop'/>
                  <p className='fw-semibold mb-2 fs_4'>Streamlined Navigation</p>
                  <p>Smart shortcuts for intuitive document search and linking.</p>
                </div>
              </div>
            </div>
          </div>
          <div className='row pad_cust_lr'>
            <div className='col-sm-12 col-lg-4 col-md-4'>
              <div class="p-4 bg-body-tertiary bod_custom rounded translate_cls">
                <div class="card-body text-secondary card_body_cust text-center">
                <img src={filesyn4} alt='' className='img-fluid p-md-3 image_drop'/>
                  <p className='fw-semibold mb-2 fs_4'>Tailored Integration</p>
                  <p>Adapt seamlessly to your business processes with customization.</p>
                </div>
              </div>
            </div>
            <div className='col-sm-12 col-lg-4 col-md-4'>
              <div class="shadow p-4 bg-body-tertiary rounded translate_cls">
                <div class="card-body text-secondary card_body_cust text-center">
                <img src={filesyn5} alt='' className='img-fluid p-md-3 image_drop'/>
                  <p className='fw-semibold mb-2 fs_4'>Smooth Transition</p>
                  <p>Ensure a hassle-free shift with effortless syncing of existing data.</p>
                </div>
              </div>
            </div>
            <div className='col-sm-12 col-lg-4 col-md-4'>
              <div class="p-4 bg-body-tertiary rounded bod_custom translate_cls">
                <div class="card-body text-secondary card_body_cust text-center">
                <img src={filesyn6} alt='' className='img-fluid p-md-3 image_drop'/>
                  <p className='fw-semibold mb-2 fs_4'>Data Security</p>
                  <p>Keep documents safe with robust security, ensuring data integrity & compliance.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default landing