import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from './components/Header';
import Footer from './components/Footer';
import Landing from './components/Landing';
// import Wrapper from './components/Wrapper/wrapper';
import Privacypolicy from './components/Privacypolicy';
import TermsCondition from './components/TermsCondition';
import 'bootstrap-icons/font/bootstrap-icons.css';

// import  Carouselcmp  from './components/Carouselcmp';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

function App() {
  return (
    <BrowserRouter>
     {/* <Routes> */}
      <Header/>
        <Routes>
          <Route path="/" element={<Landing/>}  />
          <Route path="/privacy-policies"  element={<Privacypolicy/>} /> 
          <Route path="/terms-and-conditions"  element={<TermsCondition/>} />     
        </Routes>    
      <Footer/>
     {/* </Routes> */}
    </BrowserRouter>
    
  );
}

export default App;
