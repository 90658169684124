import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import Banner1 from '../img/banner1.png';
import Banner2 from '../img/banner2.jpg';
import Banner3 from '../img/banner3.jpg';
import './carousel.css';

function carousel() {
  return (
    <div>
        <div id="carouselExampleAutoplaying" class="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <img src={Banner1} className="d-block w-100" alt="..."/>
                    <div class="carousel-caption d-md-block">
                        <h1>360 FILE SYNC</h1>
                        <p>Integrating Salesforce and Google Drive for Optimal Document Management</p>
                    </div>
                </div>
                <div className="carousel-item">
                    <img src={Banner2} className="d-block w-100" alt="..."/>
                    <div class="carousel-caption d-md-block">
                        <h1>Efficiency Redefined!</h1>
                        <p>Experience a cohesive workflow with unified platforms.</p>
                    </div>
                </div>
                <div className="carousel-item">
                    <img src={Banner3} className="d-block w-100" alt="..."/>
                    <div class="carousel-caption d-md-block">
                        <h1>Synchronize Your Data</h1>
                        <p>Stay organized and efficient with real-time data synchronization.</p>
                    </div>
                </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    </div>
  )
}

export default carousel