import React, { useEffect }  from 'react'
import './privacy.css';

function Privacypolicy() {
    useEffect(() => {
        window.scrollTo(0, 0); 
      }, []);
    const navigationsubforlder = [
        {   
             id:1,
            name:<div className="font_main_p text-dark-emphasis">Purpose</div>,
            para:<div className='font-14'>This Privacy Policy addresses all data, programs, systems, facilities, other tech infrastructure, users of technology, and Third-Parties at the Company without exception.<br/>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.</div>
        },
        {   id:2,
            name:<div className="font_main_p text-dark-emphasis">Scope</div> ,
            para:<div className='font-14'>This Privacy Policy addresses all data, programs, systems, facilities, other tech infrastructure, users of technology, and Third-Parties at the Company without exception.
            </div>
        },
        {   id:3,
            name:<div className="font_main_p text-dark-emphasis">Definitions</div>,
            para:<div className='font-14'>
                    <p>3.1. “<b>We</b>”, “<b>Our</b>”, “<b>Company</b>”, “<b>Us</b>”, “<b>360 Degree Cloud</b>” refers to “<b>360 Degree Cloud LLC, 1968 S. Coast Hwy #1412 Laguna Beach California 92651</b>” and its Affiliates.</p>
                    <p>3.2. “<b>Affiliate</b>” (or plurally “<b>Affiliates  </b>”) means any entity that controls, is controlled by or is under common control with the Party (or such other entity for which such determination is being made).  The term “control” (including the terms “controlling”, “controlled by”, and “under common control with”) means the possession, whether direct or indirect, of the power to direct or cause the direction of the management and policies of an entity or the composition of its board of directors or equivalent body, whether through the ownership of shares, by contract, or otherwise.<br/> Affiliate shall also include (i) any entity that is consolidated into either Parties group of companies or accounted for under the equity method of accounting under IFRS and/or (ii) any entity where either Party, directly or indirectly, has ownership of at least 50% of the shares.
                    <p>3.3. “<b>Client</b>” refers to any entity(es) or Company(ies) that enter into business with Us or avail Our Products or Services.</p>
                    </p>
                    <p>3.4. “<b>Party</b>” (or plural “<b>Parties</b>”) refers to Us, the Client, Third Parties, and any of their respective affiliates that enter an agreement with Us to purchase or subscribe to any Licensed Property, Service, or Product.</p>
                    <p>3.5. “<b>Third Party</b>” (or plurally “Third Parties”) refers to any entities outside of Our Company, the Client, and either of their Affiliates that provide or vend specialized software services or dependencies.</p>
                    <p>3.6. ”<b>Employee</b>” (or plural “Employees”) refers to all employees officially appointed on Our payroll.</p>
                    <p>3.7. “<b>Senior Managers</b>” refers to all team leaders that grant data access to Employees and maintain corresponding records </p>
                    <p>3.8. ”<b>Application Security Program</b>” refers to Security for flagship Products that are purchased.</p>
                    <p>3.9. “<b>Security Team</b>” is the technical team of Employees that makes Security updates and changes to fully address and report on any issues related to Vulnerability Management or issues brought up by another internal team dedicated to Security Management and Incident Response.</p>
                    <p>3.10. “<b>Product</b>” refers to all Licensed and Unlicensed Property owned by Us</p>
                    <p>3.11. “<b>Licensed Property</b>” means all the documentation and proprietary software, systems, inventions, and designs of 360 Degree Cloud, except for Deliverables.</p>
                    <p>3.12. “<b>Services</b>” means the Products and services to be provided by 360 Degree Cloud or any of its Affiliates.</p>
                    <p>3.13. “<b>People Security</b>” refers to restricted communications for sensitive data for the Company’s Employees.</p>
                    <p>3.14. “<b>Product Security</b>” refers to the failsafe and guardrails implemented to secure all Our Products from unauthorized access and control by malicious actors.</p>
                    <p>3.15. “<b>Cloud and Network Infrastructure Security</b>” refers to measures taken to ensure any data hosted over a cloud or local network is safe from unauthorized access, destruction, and modification.</p>
                    <p>3.16. “<b>Security</b>” refers to any recognized measures, best practices, protocols, enforcement, and related measures taken with the ultimate aim of securing a Product or Service or Client data from unauthorized access and modification.</p>
                    <p>3.17. “<b>Security Compliance</b>” refers to adherence and record-keeping for agreed measures and responsibilities that constitute Security.</p>
                    <p>3.18. “<b>Third Party Security</b>” refers to measures and legal obligations for a Third Party as well as Us for the Security of any Client’s data. </p>
                    <p>3.19. “<b>Vulnerability Management</b>” refers to updates to address identified, potential, or future vulnerabilities and flaws in Security for Products, Services, and any Client’s data.</p>
                    <p>3.20. “<b>Security Management and Incident Response</b>” means dealing with any emerging, persistent, or envisioned Security flaws or loopholes.</p>
                    <p>3.21. “<b>Chief Information And Security Officer</b>” is Our designated head and principal decision-maker for a dedicated internal team tasked with Security Management and Incident Response.</p>
                    <p>3.22. ”<b>Customer</b>” or “<b>Data Subject</b>” refers to anyone whose data the Client stores and collects in a Salesforce Org or Salesforce Cloud.</p>
                </div>
        },
        {   id:4,
            name:<div className="font_main_p text-dark-emphasis">References</div>,
            para:<div className='font-14'>
                <p><b>4.1. Regulatory Compliance</b></p>
                <p>Our Security Strategy is based on the ISO 27001:2013 Information Security standard.</p>
                <p><b>4.2. Physical Security Policy</b></p>
                <p>A document that describes all prerequisites in the form of measures, fail-safes, restrictions, and verifications undertaken by 360 Degree Cloud prior to and while establishing physical access or control at all locations of availability.</p>
            </div>
        },
        {   id:5,
            name:<div className="font_main_p text-dark-emphasis">Data Collection & Usage</div>,
            para:<div className='font-14'>
                <p><b>5.1. Data Collection</b></p>
                <p><b>5.1.1. Personal Data</b></p>
                <p>While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</p>
                <ul className='list'>
                    <li>First name and last name,</li>
                    <li>Phone number, and</li>
                    <li>Usage Data.</li>
                </ul>
                <p><b>5.1.2. Usage Data</b></p>
                <p>Usage Data is collected automatically when using the Service.<br/>
                Usage Data may include information such as Your Device's Internet Protocol address (e.g., IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.<br/> You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device’s unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.<br/>We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.</p>
                <p><b>5.2. Use of Your Personal Data</b></p>
                <p>To provide and maintain our Service, including monitoring the usage of our Service.</p>
                <p><b>To manage Your Account:</b> to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.</p>
                <p><b>For the performance of a contract:</b> the development, compliance, and undertaking of the purchase contract for the products, items, or services You have purchased or of any other contract with Us through the Service.</p>
                <p><b>To contact You:</b> To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products, or</p>
                <p><b>To manage Your requests:</b> To attend and manage Your requests to Us.</p>
                <p><b>For business transfers:</b> We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or another sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.</p>
                <p><b>For business transfers:</b> We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.</p>
                <p><b>With Affiliates:</b> We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners, or other companies that We control or that are under common control with Us.</p>
                <p><b>With business partners:</b> We may share Your information with Our business partners to offer You certain products, services, or promotions.</p>
                <p><b>5.3. Disclosure</b></p>
                <p><b>5.3.1. Business Transactions</b></p>
                <p>If the Company is involved in a merger, acquisition, or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.</p>
                <p><b>5.3.2. Law Enforcement</b></p>
                <p>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g., a court or a government agency).</p>
                <p><b>5.3.3. Other Legal Requirements</b></p>
                <p>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</p>
                <ul>
                    <li>Comply with a legal obligation</li>
                    <li>Protect and defend the rights or property of the Company.</li>
                    <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
                    <li>Protect the personal safety of users of the Service or the public.</li>
                    <li>Protect against legal liability.</li>
                </ul>
                <p><b>5.4. Transfer</b></p>
                <p>Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country, or other governmental jurisdiction where the data protection laws may differ from those from Your jurisdiction.<br/>Your consent to this Privacy Policy, followed by Your submission of such information, represents Your agreement to that transfer.<br/>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy, and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place, including the security of Your data and other personal information.</p>
                <p><b>5.5. Retention</b></p>
                <p>Our Retention Policy data use follows a principle of ‘end-to-end’ security from disclosure to destruction. This is in keeping with our measures for Privacy By<br/> The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.<br/>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.<br/>Still, in case any data is provided by the Client for operational purposes, it is destroyed and formatted over 13 times to prevent retrieval.</p>
            </div>
        },
        {   id:6,
            name:<div className="font_main_p text-dark-emphasis">Privacy By Enforcement</div>,
            para:<div className='font-14'>
                <p>A dedicated Privacy Team, Headed by a designated, accessible administrator, manages the Application’s Privacy program and assumes complete responsibility for all privacy-related issues. <br/>Security measures are meant to be proactive and preventative,<br/>not remedial and reactive. Our Information Security policies and standards are approved by management and available to all Employees.</p>
                <p><b>6.1. Classification Of Data</b></p>
                <p>A data classification policy may arrange the entire set of information as follows:</p>
                <p><b>6.1.1. High-Risk Class</b><br/>Data protected by state and federal legislation (the Data Protection Act, HIPAA, FERPAas well as financial, payroll, and personnel (privacy requirements) are included here.</p>
                <p><b>6.2.1. Confidential Class</b><br/>Data in this class do enjoy the privilege of being protected by laws explicitly, but nonetheless, data owners are still extended equivalent protections against unauthorized disclosure.</p>
                <p><b>6.3.1.  Public Class</b><br/>This information is available publicly and can be freely distributed.</p>
                <p><b>6.2. Data Support & Operations</b></p>
                <p>Our Security framework includes People Security, Product Security, Cloud and Network Infrastructure Security, Security Compliance, Third Party Security, Vulnerability Management, as well as Security Monitoring and Incident Response, Support, and operations like the regulation of general system mechanisms responsible for data protection, data backup, and movement of data.</p>
                <p><b>6.3. Authority, Access Control, and Restrictions</b></p>
                <p>Permissions and access to any Client’s data, if found necessary for operation, are shared sparingly on a ‘need-to-know’ basis with a due record of access. Employees with access are legally bound not to share the little amount of information they may have unless explicitly authorized.<br/>This is affected in ways that Senior Managers may have adequate authority to make a decision on what data can be shared and with whom while assuming responsibility for keeping due records of access.<br/>Monitoring on all systems must be implemented to record login and access attempts.</p>
                <p><b>6.4. Legal Obligations</b></p>
                <p>We ourselves, Third-Parties, and all affiliates thereof are contractually obligated to take meaningful active consent (or seek obligations for the same) in accordance with mandated local regulations before storing any user Data in an Org.<br/>Obligations also include all situations enumerated in the Section titled “Privacy Rights & Data Removal.”</p>

            </div>
        },
        {   id:7,
            name:<div className="font_main_p text-dark-emphasis">Privacy By Transparency</div>,
            para:<div className='font-14'>
                <p><b>7.1. Changes and Approvals</b></p>
                <p><b>7.1.1. Privacy Policy</b></p>
                <p>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page from our Data Protection Team.<br/>We will let You know via email and/or a prominent notice on Our Service prior to the change becoming effective and update the "Last Updated" date at the top of this Privacy Policy.<br/>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
                <p><b>7.1.2. Security Policy</b></p>
                <p>Security is represented at the highest levels of the Company, with Our Chief Information Security Officer meeting with executive management regularly to discuss issues and coordinate Company-wide Security initiatives.</p>
                <p><b>7.2. Disclosures</b></p>
                <p>It is possible that we will need to disclose information about you when required by law, subpoena, or other legal processes or if we have a good faith belief that disclosure is reasonably necessary to:</p>
                <ul>
                    <li>Investigate, prevent, or take action regarding suspected or actual illegal activities or to assist government enforcement agencies,</li>
                    <li>Enforce our agreements with you,</li>
                    <li>Investigate and defend ourselves against any third-party claims or allegations,</li>
                    <li>Protect the security or integrity of our Service (such as by sharing with companies facing similar threats).</li>
                </ul>
                <p>We will attempt to notify you about legal demands for your personal data when appropriate in our judgment unless prohibited by law or court order or when the request is an emergency. <br/>We may dispute such demands when we believe, in our discretion, that the requests are overbroad, vague, or lack proper authority, but we do not promise to challenge any demand.</p>
            </div>
        },
        {   id:8,
            name:<div className="font_main_p text-dark-emphasis">Security Commitments for Privacy</div>,
            para:<div className='font-14'>
                <p>We consider Our attitude towards Security as a vital part of our commitment to and enforcement of Privacy.<br/>Security is a top priority for 360 Degree Cloud. We use the best security practices and policies to ensure that Our network is secured physically and virtually and that Our customer’s data is both private and secure. We do not save the data of the organizations that install our app.<br/>Our Security practices and policies offer a transparent look into Our operations and the the support that We offer Our Clients and their Customers (or Data Subjects).</p>
                <p><b>8.1. Privacy By Design</b></p>
                <p>8.1.1. At 360 File Sync, all operations are ‘zero-local-storage’, by design, even accelerated bulk messaging. That means any communications you send out or receive in confidence or otherwise live only on Salesforce’s cloud storage within your Org.</p>
                <p><b>8.2. Privacy By Architecture Security</b></p>
                <p>8.2.1. Our Applications are completely ‘Salesforce-native’, which means Client data is hosted exclusively over the Client’s own Salesforce Cloud instance and doesn’t store any NPI (Non-Public Information) of the Client’s customers on any physical server of Our own. <br/>8.2.2. Penetration Testing is used as the primary security assessment type.<br/>8.2.3. Security engineers continuously perform numerous activities to ensure that our Products are secure. <br/>These include internal security reviews before products are launched, regular penetration tests performed by Third-Party contractors, continuously running bug bounty programs, continuously running internal and external security tests, and regularly conducted performance tests against threat models.</p>
                <p><b>8.3. Privacy By Default</b></p>
                <p><b>8.3.1. Default Commitment & Intentions</b></p>
                <p>We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we’re collecting it and how it will be used.<br/>We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we’ll protect within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use, or modification.<br/>We don’t share any personally identifying information publicly or with third parties except when required to by law.<br/>Even in events not covered by this Privacy Policy, We intend to uphold Privacy for all Clients and their Customers (or Data Subjects) in good faith.<br/>Your continued use of our app will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us.</p>
            </div>
        },
        {   id:9,
            name:<div className="font_main_p text-dark-emphasis">Compliance Measures</div>,
            para:<div className='font-14'>
                <p><b>9.1. Assigned Privacy and Security Responsibility</b></p>
                <p><b>9.1.1. Security Program</b><br/>While security is a high priority for all Our teams, a dedicated Security Team manages Our Security Program.</p>
                <p><b>9.1.2. Security Alert Response</b><br/>Security alerts are directed at IT managers who can speak to Development Team coordinators and are followed up with a proportionate response and disciplinary measures.</p>
                <p><b>9.1.3. Security Initiatives</b><br/>Data Protection and Chief Information Security Officers (and their respective teams) discuss and lead Our Security initiatives. </p>
                <p><b>9.2. Standard Compliance Measures & Best Practices</b></p>
                <p>Applications by 360 Degree Cloud adhere to a host of Privacy principles and best practices required by most compliance regulations by design through architecture. Since 360 File Sync is natively built on Salesforce, it doesn’t store Customer information on any physical server of its own. <br/>The app also encrypts the messages, thus ensuring data encryption and privacy.</p>
            </div>
        },
        {   id:10,
            name:<div className="font_main_p text-dark-emphasis">Site Usage</div>,
            para:<div className='font-14'>Our Service or Website may contain links to other websites that are not operated by Us. If You click on a Third-Party link, You will be directed to that third-party's site. We strongly advise You to review the Privacy Policy of every site You visit.<br/>We have no control over and assume no responsibility for the content, privacy policies 
            or practices of any Third-Party sites or Services.</div>
        },
        {   id:11,
            name:<div className="font_main_p text-dark-emphasis">Privacy Rights And Data Removal</div>,
            para:<div className='font-14'>
                <p><b>11.1. Your Rights As A Data Subject</b></p>
                <p>At any point, while we are in possession of or processing your personal data, you, the data subject, have the following rights:</p>
                <ul>
                    <li>Right of access – you have the right to request a copy of the information that we hold about you.</li>
                    <li>Right of rectification – you have a right to correct data that we hold about you that is inaccurate or incomplete.</li>
                    <li>Right to be forgotten – in certain circumstances, you can ask for the data we hold about you to be erased from our records.</li>
                    <li>Right to restriction of processing – where certain conditions apply to have a right to restrict the processing.</li>
                    <li>Right of portability – you have the right to have the data we hold about you transferred to another organization.</li>
                    <li>Right to object – you have the right to object to certain types of processing, such as direct marketing.</li>
                    <li>Right to object to automated processing, including profiling – you also have the right to be subject to the legal effects of automated processing or profiling.</li>
                    <li>Right to judicial review – in the event that 360 File Sync refuses your request under rights of access, we will provide you with a reason as to why.</li>
                </ul>
                <p>You may also contact us using the contact information below, and we will consider your request in accordance with applicable laws.</p>
                <p><b>11.2. Data Removal & Complaints</b></p>
                <p>To request data removal or in the event that you wish to make a complaint about how your personal data is being processed by 360 File Sync (or third parties as described above) or how your complaint has been handled, you have the right to lodge a complaint directly with the supervisory authority with Our Data Protection Officer.</p>
            </div>
        },
      ]

      const currentDate = new Date();

  // Format the date as a string (e.g., "October 17, 2023")
  const formattedDate = currentDate.toLocaleDateString();
  return (
    <div className='p-100'>
        <div className='text-center pt-3'>
            <p>This policy is effective as of {formattedDate}.</p>
            
            <p className='fw-semibold fs-5 text-body-emphasis'>Privacy Policy</p>
        </div>
        <div className='px-2 px-lg-5'>
            <p className='font_main_p'>Preamble</p>
            <p className='pt-3'>Your privacy is important to us. It is 360 Degree Cloud's policy to respect your privacy regarding any information we may collect from you through our app, 360 File Sync.</p>
            <ol className="list-group list-group-numbered">
            {navigationsubforlder.map((item) => (
            <li className="list-group-item d-flex justify-content-between align-items-start p-2 border border-0">
                    <div className="ms-2 me-auto">
                        <div className="fw-bold">{item.name}</div>
                        {item.para}
                    </div>
                </li>
            ))}


            </ol>
        </div>
    </div>
  )
}

export default Privacypolicy