import React from 'react';
import {Link} from 'react-router-dom';
import './header.css';
import headerlogo from '../img/360FileSyncLogo.svg';

function header() {
  return (
    <nav className="navbar fixed-top navbar-expand-lg bg-body-tertiary shadow-sm">
      <div className="container-fluid items-center">
          <Link className="navbar-brand" to="/">
            <img alt='360 File Sync Logo' style={{ width: '55px' }} src={headerlogo}/>
          </Link>
        <button className="border-none navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
        <ul className="navbar-nav me-auto mb-2 mb-lg-0 column-gap-3 cust_cl-auto">
            <li className="nav-item">
              <Link className="nav-link active"  to="tel:+13093167929">
                <img alt='' src="https://360smsapp.com/wp-content/uploads/2021/10/255px-Flag_of_the_United_States.svg-p40ovmwmdvx098zmnerozbu9ngrs21itasgdcv5an4.png" />
                <span className='ms-2 fs-6 text-info-emphasis'>+1 309 316 7929</span>
              </Link>
            </li>
            <li className="nav-item">
            <Link className="nav-link active"  to="tel:+447403279473">
                <img alt='' src="https://360smsapp.com/wp-content/uploads/2021/10/255px-Flag_of_the_United_Kingdom.svg-p40ox2lqtrvrzswj9j46agsmbnoyte81twc2r30n4w.png" />
                <span className='ms-2 fs-6 text-info-emphasis'>+44 789 703 1106</span>
              </Link>
            </li>
            <li className="nav-item">
            <Link className="nav-link active"  to="tel:+61488856713">
                <img alt='' src="https://360smsapp.com/wp-content/uploads/2021/10/255px-Flag_of_Australia_converted.svg.webp" />
                <span className='ms-2 fs-6 text-info-emphasis'>+ 61 4800 94870</span>
              </Link>
            </li>
            <li className="nav-item">
            <Link className="nav-link active"  to="tel:8069027849">
                <img alt='' src="https://360smsapp.com/wp-content/uploads/2021/10/255px-Flag_of_India.svg.webp" />
                <span className='ms-2 fs-6 text-info-emphasis'>+91 8069 0278 49</span>
              </Link>
            </li>
          </ul>
          {/* <form className="d-flex" role="search">
            <button className="btn btn-outline-info" type="submit">Contact Us</button>
          </form> */}
        </div>
      </div>
    </nav>
  )
}

export default header